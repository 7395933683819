@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    @apply max-w-[350px] h-[120px] bg-white rounded-lg shadow-lg overflow-hidden m-4 p-3 border-2 border-[#EEEEEE] font-Nunito;
  }

  .card-title {
    @apply text-lg font-semibold text-[#3F3F3F] mb-2;
  }

  .card-description {
    @apply text-sm text-[#8F8F8F] leading-normal;
  }
}
